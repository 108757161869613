body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

/* GLOBAL LOADER */
.overlay {
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #0000009a;
  z-index: 1000;
}
.overlayInner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.overlayContent {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.globalSpinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* BACKGROUND COLORS */

.bgGreen {
  background-color: #008b46;
}

.bgBlue50 {
  background-color: #accbe6;
}

.bgBlue {
  background: #007bff;
}

.bgBlueHead {
  background: #00acdb;
}

.bgBlue10 {
  background-color: #e2efff;
}

.bgGrey {
  background: rgb(196, 196, 196);
}

/* Theme */

.themeError {
  background-color: rgb(240, 144, 144);
  color: brown;
}

/* COLORS */

.colorWhite {
  color: #ffff;
}

.colorGreen {
  color: #008b46;
}

.colorNavy {
  color: #024987;
}

.colorBlue {
  color: #007bff;
}

.colorBlue50 {
  color: #2892b1;
}

.colorRed {
  color: red;
}

.breadcrumb-item {
  cursor: pointer;
}

th.cellDefault {
  text-align: center;
  min-width: 95px;
  border: 1px solid grey;
}

td.cellDefault {
  text-align: center;
  /* min-width: 95px; */
}
/* 
.treeHead {
  min-width: 220px;
}
*/

.toogleSwitchText {
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* OTHERS */
.toggleSwitch {
  cursor: pointer;
  display: flex;
}
.cursorPointer {
  cursor: pointer;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important;
}

.treeviewTableBox {
  background-color: #fff;
  overflow: auto;
  max-height: calc(100vh - 360px);
}

code {
  font-family: "Open Sans", sans-serif;
}

.bgcolorBlue {
  background: #007bff;
}

.Loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  min-width: 185px;
}
/* .modal-header .btn-close {
  min-width: none !important;
} */
.tableBorder {
  border: 1px solid rgb(196, 196, 196);
}

.formWidth {
  max-width: 700px;
}

.historyTableWidth {
  max-width: 1400px;
}

.goToHistoryBtn {
  padding: 0.5rem;
  background: #00acdb;
  font-weight: bold;
  color: white;
  border-radius: 0.25rem;
  text-decoration: none;
}
